// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bewerbungsformular-js": () => import("./../../../src/pages/bewerbungsformular.js" /* webpackChunkName: "component---src-pages-bewerbungsformular-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fachpartner-js": () => import("./../../../src/pages/fachpartner.js" /* webpackChunkName: "component---src-pages-fachpartner-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriere-js": () => import("./../../../src/pages/karriere.js" /* webpackChunkName: "component---src-pages-karriere-js" */),
  "component---src-pages-kontaktformular-js": () => import("./../../../src/pages/kontaktformular.js" /* webpackChunkName: "component---src-pages-kontaktformular-js" */),
  "component---src-pages-kooperationenformular-js": () => import("./../../../src/pages/kooperationenformular.js" /* webpackChunkName: "component---src-pages-kooperationenformular-js" */),
  "component---src-pages-partnerschaft-js": () => import("./../../../src/pages/partnerschaft.js" /* webpackChunkName: "component---src-pages-partnerschaft-js" */),
  "component---src-pages-uberuns-js": () => import("./../../../src/pages/uberuns.js" /* webpackChunkName: "component---src-pages-uberuns-js" */),
  "component---src-templates-service-page-jsx": () => import("./../../../src/templates/ServicePage.jsx" /* webpackChunkName: "component---src-templates-service-page-jsx" */)
}

